const abs = {
  vrrap: 'VRRAP',
  vettec: 'VETTEC',
  'vet-tec': 'VETTEC',
  wioa: 'WIOA',
  army_cool: 'Army COOL',
  airforce_cool: 'AirForce COOL',
  vrne: 'VR&E',
  mycaa: 'MYCAA',
  gi_bill: 'G.I. BILL',
  elearning_bundle: 'eLearning Bundle',
};

export const title = (value, replacer = ' ') => {
  if (!value) return '';
  if (abs[value]) return abs[value];
  if (value === true) value = 'Yes';
  if (value === false) value = 'No';

  let str = value.toString();

  if (str === 'na') str = 'N/A';

  const arr = str.replaceAll('_', replacer).split(replacer);
  const capitalizedArray = [];
  arr.forEach((word) => {
    const capitalized = word.charAt(0).toUpperCase() + word.slice(1);
    capitalizedArray.push(capitalized);
  });
  return capitalizedArray.join(' ');
};

export const abbreviate = (value) => {
  if (!value) return '';
  return value
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
    .substr(0, 5);
};

export const avatarText = (value) => {
  if (!value) return '';
  const nameArray = value.split(' ');
  return nameArray
    .map((word) => word.charAt(0).toUpperCase())
    .join('')
    .substr(0, 3);
};

export const gradeScore = ({ score, precision, percent, status } = { score: 0, precision: 1, percent: false, status: null }) => {
  if (status && ['credited', 'terminated', 'withdrew', 'withdrew_s1', 'withdrew_s2', 'withdrew_s3'].includes(status))
    return `N/A (${abbreviate(status)})`;
  return parseFloat((score * 100).toFixed(precision)) + (percent ? '%' : '');
};

const gradeLetter = ({ score } = { score: 0 }) => {
  if (score < 0.6) return 'F';
  if (score < 0.7) return 'D';
  if (score < 0.75) return 'D+';
  if (score < 0.8) return 'C';
  if (score < 0.85) return 'B+';
  if (score < 0.9) return 'B';
  if (score < 0.93) return 'A';
  return 'A+';
};
