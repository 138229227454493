import axios from '@axios';

const searchProfiles = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    // delete queryParams.filter;

    axios
      .get('v2/roster/profiles/search', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const searchProfilesDownload = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get('v2/roster/profiles/search/export', {
        params: queryParams,
        responseType: 'blob',
      })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchProfiles = (ctx, queryParams) => {
  return new Promise((resolve, reject) => {
    // delete queryParams.filter;

    axios
      .get('v2/roster/profiles', { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const fetchProfile = (ctx, { id, queryParams }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`v2/roster/profiles/${id}`, { params: queryParams })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const addProfile = (ctx, profileData) => {
  return new Promise((resolve, reject) => {
    axios
      .post('v2/roster/profiles', profileData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateProfile = (ctx, { id, profileData }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/profiles/${id}`, profileData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const updateProfileHistory = (ctx, { profileId, historyData }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/profiles/${profileId}/history`, historyData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

const assignCourseMakeup = (ctx, { profileId, sectionId, enabled }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/profiles/${profileId}/makeup`, { enabled, sectionId })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const assignCourseCredit = (ctx, { id, creditData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/profiles/${id}/credit`, creditData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const unassignCourseCredit = (ctx, { id, creditData }) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`v2/roster/profiles/${id}/credit/remove`, creditData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const assignProfileToArAccount = (ctx, { profileId, accountId }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(`v2/roster/profiles/${profileId}/account`, { accountId })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
const updateProfiles = (ctx, { ids, profileData }) => {
  return new Promise((resolve, reject) => {
    axios
      .patch('v2/roster/profiles', { ids, body: profileData })
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
export default {
  searchProfiles,
  searchProfilesDownload,
  fetchProfiles,
  fetchProfile,
  addProfile,
  updateProfile,
  updateProfileHistory,
  assignCourseMakeup,
  assignCourseCredit,
  unassignCourseCredit,
  assignProfileToArAccount,
  updateProfiles,
};
